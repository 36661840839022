import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axios from "axios";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import CustomSelect from "./CustomSelect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Assuming you're using AOS for animations
import bottomshape from "../../assets/images/bottomshape.png";
import signup from "../../assets/images/signup.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { Col, Row, Form } from "react-bootstrap";
import Footer from "../../Comman/Footers/Footer";
import Navbar from "../../Comman/Header/Navbar";
import { Link } from "react-router-dom";

const SignUpSection = () => {
  //loder
  const { promiseInProgress } = usePromiseTracker();
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  // Phone number state
  // const [mobilenumber, setmobilenumber] = useState("");
  // const [valid, setvalid] = useState(false);
  // const [mobilevalid, setmobilevalid] = useState(false);
  // const [mobilevalidationerror, setmobilevalidationerror] = useState("");

  // const [experiencelevel, setExperiencelevel] = useState("");
  // const [projecttype, setProjecttype] = useState("");
  // const [timeline, setTimeline] = useState("");

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      // setmobilevalidationerror("Please Enter A valid Mobile Number");
      formik.setFieldError("phone", "Invalid phone number");
      // setmobilevalid(false);
    } else {
      // setmobilevalidationerror("");
      formik.setFieldError("phone", "");
      // setmobilevalid(true);
    }
  };



  // function mobilevalidation(phone) {
  //   // Custom phone validation logic
  //   if (!/^[0-9+].{6,12}$/.test(phone)) {
  //     formik.setFieldError("phone", "Invalid phone number");
  //   } else {
  //     formik.setFieldError("phone", "");
  //   }
  // }


  const [resumefile, setResumefile] = useState(null);
  // Create a ref for the file input
  const fileInputRef = useRef(null);

  // // Tag input state management
  // const [tags, setTags] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  // const [searchinputtag, setSearchinputtag] = useState([])
  // const options5 = [
  //   { title: "node" },
  //   { title: "react" },
  //   { title: "mongodb" },
  //   { title: "flutter" },
  //   { title: "js" },
  // ];

  // // Handle tag input change
  // const handleInputChange = (e) => {
  //   const searchTerm = e.target.value;
  //   setInputValue(searchTerm);
  //   const tagsearchlist = filterEvents(options5, searchTerm);
  //   setSearchinputtag([...tagsearchlist])
  // };

  // const tagsToDisplay = inputValue ? searchinputtag : [...options5];


  // // Function to filter events based on search term
  // const filterEvents = (events, searchTerm) => {
  //   console.log(events, "events")
  //   return events.filter((event) =>
  //     event.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // };
  // // Handle adding a new tag
  // const handleAddTag = (e) => {
  //   if (e.key === "Enter" && inputValue) {
  //     setTags([...tags, inputValue]);
  //     setInputValue(""); // Clear input after adding
  //   }
  // };

  // // Handle removing a tag
  // const removeTag = (index) => {
  //   setTags(tags.filter((_, i) => i !== index));
  // };

  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  // Tag input state management
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchinputtag, setSearchinputtag] = useState([]);
  const options5 = [
    { title: "Node js" },
    { title: "React js" },
    { title: "Mongodb" },
    { title: "Flutter" },
    { title: "Javascript" },
    { title: "HTML" },
    { title: "CSS" },
    { title: "C++" },
    { title: "PHP" },
    { title: "JAVA" },
    { title: "Python" },
    { title: "Next js" },
    { title: "React native" },
    { title: "Typescript" },
    { title: "Jquery" },
  ];

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setInputValue(searchTerm);
    const tagsearchlist = filterEvents(options5, searchTerm);
    setSearchinputtag([...tagsearchlist]);
  };

  const filterEvents = (events, searchTerm) => {
    return events.filter((event) =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // const handleAddTag = (e) => {
  //   if (e.key === "Enter" && inputValue) {
  //     setTags([...tags, inputValue]);
  //     setInputValue(""); // Clear input after adding
  //   }
  // };



  const handleAddTag = (e) => {
    if (e.key === "Enter" && inputValue) {
      setTags([...tags, inputValue]);
      formik.setFieldValue('tags', [...tags, inputValue]);  // Update Formik's tags
      setInputValue(""); // Clear input after adding
    }
  };



  //   const handleAddTag = (e) => {
  //     if (e.key === "Enter" && inputValue) {
  //         // Add tag only if it is not already in the list
  //         if (!tags.includes(inputValue)) {
  //             setTags([...tags, inputValue]);
  //         }
  //         setInputValue(""); // Clear input after adding
  //     }
  // };

  const addTagFromSearch = (tag) => {
    // Add tag only if it is not already in the list
    if (!tags.includes(tag.title)) {
      // setInputValue([...tags, tag.title]);
      setInputValue(tag.title);

    }
    setSearchinputtag([])
    // setInputValue(tag); // Clear input after adding

  };
  // const removeTag = (index) => {
  //   const newTags = tags.filter((_, i) => i !== index);
  //   setTags(newTags);
  // };

  const removeTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    formik.setFieldValue('tags', newTags);  // Update Formik's tags
  };



  const tagsToDisplay = inputValue ? searchinputtag : [];

  const options2 = [
    { value: "0-6 Month", title: "0-6 Month" },
    { value: "6 Month - 1 Year", title: "6 Month - 1 Year" },
    { value: "1 Year - 2 Year", title: "1 Year - 2 Year" },
    { value: "2 Year - 3 Year", title: "2 Year - 3 Year" },
    { value: "3+ Year", title: "3+ Year" },
  ];
  const options3 = [
    { value: "Consultation", title: "Consultation" },
    { value: "Full-time", title: "Full-time" },
    { value: "Part-time", title: "Part-time" },
    { value: "Freelance", title: "Freelance" },
    { value: "Etc", title: "Etc" },
  ];
  const options4 = [
    { value: "Less than 1 Month", title: "Less than 1 Month" },
    { value: "1 Month - 2 Months", title: "1 Month - 2 Months" },
    { value: "2 Months - 3 Months", title: "2 Months - 3 Months" },
    { value: "3 Months - 6 Months", title: "3 Months - 6 Months" },
    { value: "More than 6 Months", title: "More than 6 Months" },
  ];


  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (!imageFile.name.match(/\.(doc|docx|pdf|txt)$/)) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };




  // api calling for request a quotation
  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.fname);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("experience_level", values.experiencelevel);
    bodyFormData.append("expertise_area", tags);
    bodyFormData.append("project_type", values.projecttype);
    bodyFormData.append("timeline", values.timeline);
    bodyFormData.append("message", values.description);
    bodyFormData.append("requirement", resumefile);
    const url = `${process.env.REACT_APP_BASE_URL}/hireexperts`;
    trackPromise(
      axios
        .post(url, bodyFormData, {
          "Content-Type": "multipart/form-data",
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.status_code === 200) {
            showAlert(response.data.message)
            // setmobilenumber("")
            setTags([])
            setResumefile(null)
            // setTimeline("")
            // setExperiencelevel("")
            // setProjecttype("")

            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          }
          else if (response.data.status_code === 201) {
            showToastMessageone(response.data.message.name);
            showToastMessageone(response.data.message.email);
            showToastMessageone(response.data.message.phone);
            showToastMessageone(response.data.message.experience_level);
            showToastMessageone(response.data.message.expertise_area);
            showToastMessageone(response.data.message.project_type);
            showToastMessageone(response.data.message.timeline);
            showToastMessageone(response.data.message.message);
            showToastMessageone(response.data.message.requirement);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    )
  }



  // Formik form handler
  const formik = useFormik({
    initialValues: {
      fname: "",
      email: "",
      phone: "",
      experiencelevel: "",
      projecttype: "",
      timeline: "",
      description: "",
      tags: [],
    },
    validationSchema: Yup.object({
      fname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      description: Yup.string()
        .required("Required!"),

      phone: Yup.string().required("Phone number is required"),

      tags: Yup.array()
        .min(1, "At least one tag is required")
        .required("Tags are required"),

      projecttype: Yup.string().required("You must select an project type!"),
      experiencelevel: Yup.string().required("You must select an experience level!"),
      timeline: Yup.string().required("You must select an timeline!"),

      // experiencelevel: Yup.string().notOneOf([""], "You must select an option experience level!"),

    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

  {
    formik.errors.tags && formik.touched.tags && (
      <p className="errormessage">{formik.errors.tags}</p>
    )
  }


  return (
    <>
      <Navbar />
      <section className="sign-up auth-container inner-hero-section overflow-hidden texture-bg-2 pt-120">
        <div className="container pt-120">
          <div className="row g-6 justify-content-between">
            <div className="col-lg-6">
              <div className="sign-up-form" data-aos="fade-up">
                <form onSubmit={formik.handleSubmit} className="auth-form p-xl-8 p-4 bgc-2 rounded" >
                  <div className="form-content">
                    <h3 className="mb-1">Let’s Get Started!</h3>
                    <p className="mb-3">
                      Please submit the details about your requirement
                    </p>
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="fname" className="mb-2">Name</label>
                          <Form.Control
                            placeholder="Enter Your Name"
                            type="text"
                            id="fname"
                            name="fname"
                            value={formik.values.fname}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.fname && formik.touched.fname && (
                            <p className="errormessage">{formik.errors.fname}</p>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="email" className="mb-2">Email</label>
                          <Form.Control
                            placeholder="Enter Your Email"
                            type="email"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <p className="errormessage">{formik.errors.email}</p>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="phone" className="mb-2">Phone Number</label>
                          {/* <PhoneInput
                            country={"in"}
                            value={mobilenumber}
                            onChange={(phone) => {
                              setmobilenumber(phone);
                              mobilevalidation(phone);
                              formik.setFieldValue("phone", phone);
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                              style: { marginLeft: "0px", width: "100%" },
                            }}
                            isValid={(validmobilenumber) => {
                              if (
                                !new RegExp(/^[0-9+].{6,12}$/).test(validmobilenumber)
                              ) {
                                setvalid(false);
                                return false;
                              } else {
                                setvalid(true);
                                return true;
                              }
                            }} /> */}
                          <PhoneInput
                            country={"in"}
                            value={formik.values.phone}
                            onChange={(phone) => {
                              formik.setFieldValue("phone", phone);
                              mobilevalidation(phone);
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false,
                              style: { marginLeft: "0px", width: "100%" },
                            }}
                          />

                          {/* <span>
                            {mobilevalid ? (
                              <></>
                            ) : (
                              <><p className="errormessage">{mobilevalidationerror}</p></>
                            )}
                          </span> */}

                          {formik.errors.phone && formik.touched.phone ? (
                            <p className="errormessage">{formik.errors.phone}</p>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="experienceLevel" className="mb-2">Experience Level</label>
                          <CustomSelect
                            options={options2}
                            placeholder="Experience Level"
                            selectedOption={formik.values.experiencelevel} // Bind Formik's value
                            onChange={(value) => formik.setFieldValue('experiencelevel', value.title)}
                          // selectedOption={experiencelevel}
                          // onChange={(value) => setExperiencelevel(value.title)}
                          // value={formik.values.experienceLevel}
                          // onChange={(option) => formik.setFieldValue("experienceLevel", option.value)}
                          />
                          {/* {formik.errors.experiencelevel && formik.touched.experiencelevel && (
                            <p className="errormessage">{formik.errors.experiencelevel}</p>
                          )} */}

                          {formik.errors.experiencelevel && formik.touched.experiencelevel ? (
                            <p className="errormessage">{formik.errors.experiencelevel}</p>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="expertiseArea" className="mb-2">Expertise Area</label>
                          <div className="tags-input-container">
                            <ul className="tags-list">
                              {tags.map((tag, index) => (
                                <li key={index} className="tag-item">
                                  {tag}
                                  <span
                                    className="remove-tag"
                                    onClick={() => removeTag(index)}
                                  >
                                    &times;
                                  </span>
                                </li>
                              ))}
                            </ul>
                            <input
                              type="search"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleAddTag}
                              className="form-control-1"
                              placeholder="Expertise Tag"
                            />
                            <ul className="list-of-tags">
                              {tagsToDisplay.map((datatags) => {
                                return (
                                  <li>{datatags.title}</li>
                                )
                              })}
                            </ul>
                          </div>
                        </Form.Group>
                      </div>
                    </Col> */}

                    <Col md={12}>
                      <div className="expert-siup">
                        {/* <Form.Group>
                          <label htmlFor="expertiseArea" className="mb-2">Expertise Area</label>
                          <div className="tags-input-container">
                            <ul className="tags-list">
                              {tags.map((tag, index) => (
                                <li key={index} className="tag-item">
                                  {tag}
                                  <span
                                    className="remove-tag"
                                    onClick={() => removeTag(index)}
                                  >
                                    &times;
                                  </span>
                                </li>
                              ))}
                            </ul>
                            <input
                              type="search"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleAddTag}
                              className="form-control-1"
                              placeholder="Expertise Tag"
                            />
                            <ul className="list-of-tags">
                              {tagsToDisplay.map((datatags, index) => (
                                <li onClick={() => addTagFromSearch(datatags)} key={index} className="list-of-search-tag">{datatags.title}</li>
                              ))}
                            </ul>
                          </div>
                        </Form.Group> */}

                        <Form.Group>
                          <label htmlFor="expertiseArea" className="mb-2">Expertise Area</label>
                          <div className="tags-input-container">
                            <ul className="tags-list">
                              {tags.map((tag, index) => (
                                <li key={index} className="tag-item">
                                  {tag}
                                  <span className="remove-tag" onClick={() => removeTag(index)}>&times;</span>
                                </li>
                              ))}
                            </ul>
                            <input
                              type="search"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleAddTag}
                              className="form-control-1"
                              placeholder="Expertise Tag"
                            />
                            <ul className="list-of-tags">
                              {tagsToDisplay.map((datatags, index) => (
                                <li onClick={() => addTagFromSearch(datatags)} key={index} className="list-of-search-tag">{datatags.title}</li>
                              ))}
                            </ul>
                          </div>
                          {/* {/ Display tag validation error /} */}
                          {formik.errors.tags && formik.touched.tags && (
                            <p className="errormessage">{formik.errors.tags}</p>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="projectType" className="mb-2">Project Type</label>
                          <CustomSelect
                            options={options3}
                            placeholder=" Project Type"
                            // selectedOption={projecttype}
                            // onChange={(value) => setProjecttype(value.title)}
                            selectedOption={formik.values.projecttype} // Bind Formik's value
                            onChange={(value) => formik.setFieldValue('projecttype', value.title)}

                          // value={formik.values.projectType}
                          // onChange={(option) => formik.setFieldValue("projectType", option.value)}
                          />

                          {formik.errors.projecttype && formik.touched.projecttype ? (
                            <p className="errormessage">{formik.errors.projecttype}</p>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="timeline" className="mb-2">Timeline</label>
                          <CustomSelect
                            placeholder="Timeline"
                            options={options4}
                            // selectedOption={timeline}
                            // onChange={(value) => setTimeline(value.title)}
                            selectedOption={formik.values.timeline}
                            onChange={(value) => formik.setFieldValue("timeline", value.title)}
                          />
                          {formik.errors.timeline && formik.touched.timeline ? (
                            <p className="errormessage">{formik.errors.timeline}</p>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="rqfrom-us-new">
                        <div className="field file-upload">
                          <label htmlFor="request" className="Request_field">
                            Upload Your Requirement
                          </label>
                          <input
                            type="file"
                            className="form-control custom-file-input"
                            id="uploadfile"
                            ref={fileInputRef}
                            onChange={(e) => {
                              upload(e);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="expert-siup">
                        <Form.Group>
                          <label htmlFor="description" className="mb-2">About Your Message</label>
                          <Form.Control
                            placeholder="Enter Your Message"
                            as="textarea"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            style={{ height: "100px" }}
                          />
                          {formik.errors.description && formik.touched.description && (
                            <p className="errormessage">{formik.errors.description}</p>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  {/* <p className="my-lg-8 my-sm-6 mb-3">
                    By signing up, you agree to our
                    <Link to="/terms-and-condition" className="tcp-1">
                      Terms and Conditions
                    </Link>
                    ,{""}
                    <Link to="/privacy-policy" className="tcp-1">
                      Privacy Policy
                    </Link>
                    , E-sign & Communication Authorization
                  </p> */}
                  {promiseInProgress ? (
                    <div className="spinner flex justify-center items-center h-12">
                      <ThreeDots
                        height="60"
                        width="60"
                        color="#ffb430"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <button className="bttn-1" type="submit">
                      Hire Experts
                      <span className="icon d-center icon-right">
                        <i>
                          <FaArrowRightLong />
                        </i>
                      </span>
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6">
              <div className="auth-banner req-form-image mt-5" data-aos="fade-down">
                <img className="w-100" src={signup} alt="sign up" />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-wave-shape">
          <img className="w-100" src={bottomshape} alt="wave shape" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignUpSection;
