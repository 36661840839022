import aap6 from '../../assets/images/portfolio/aap6.png';
import aap1 from '../../assets/images/portfolio/aap1.png';
import aap11 from '../../assets/images/portfolio/aap11.png';
import aap3 from '../../assets/images/portfolio/aap3.png';
import aap2 from '../../assets/images/portfolio/aap2.png';
import aap4 from '../../assets/images/portfolio/aap4.png';
import aap5 from '../../assets/images/portfolio/aap5.png';
import aap8 from '../../assets/images/portfolio/aap8.png';
import website1 from '../../assets/images/portfolio/website1.png';
import website2 from '../../assets/images/portfolio/website2.png';
import website3 from '../../assets/images/portfolio/website3.png';
import website4 from '../../assets/images/portfolio/website4.png';
import website5 from '../../assets/images/portfolio/website5.png';
import website6 from '../../assets/images/portfolio/website6.png';
import website7 from '../../assets/images/portfolio/website7.png';
import website8 from '../../assets/images/portfolio/website8.png';
import website9 from '../../assets/images/portfolio/website9.png';
import website10 from '../../assets/images/portfolio/website10.png';
import website11 from '../../assets/images/portfolio/website11.png';

const MenuItems = [
    {
        id: 1,
        image: aap6,
        name: "FAMILY FM LTD.Antigua",
        category: "app",
        description: 'Android App -> iOS App'
    },

    {
        id: 2,
        image: aap1,
        name: "Bct Pay",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 3,
        image: aap11,
        name: "Soundchat Radio",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 4,
        image: aap3,
        name: "Reegur",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 5,
        image: aap2,
        name: "Payroos",
        category: "app",
        description: 'Android App -> iOS App '
    },
    {
        id: 6,
        image: aap6,
        name: "Time less ",
        category: "app",
        description: 'Android App -> iOS App'
    },
    {
        id: 7,
        image: aap8,
        name: "Rock vibe gospal radio",
        category: "app",
        description: 'Android App -> iOS App '
    },
    {
        id: 8,
        image: aap4,
        name: "Catholic radio",
        category: "app",
        description: 'Android App -> iOS App '
    },


    {
        id: 9,
        image: website1,
        name: "Pragati manav seva sansthan",
        category: "Website",
        description: 'Website '
    },
    {
        id: 10,
        image: website2,
        name: "Mahakal takniki shiksha samiti",
        category: "Website",
        description: 'Website '
    },

    {
        id: 11,
        image: website3,
        name: "Pettershell",
        category: "Website",
        description: 'Website '
    },
    // {
    //     id: 12,
    //     image: "website9.74f84a43.png",
    //     name: "Gihlot consultant",
    //     category: "Website",
    //     description: 'Website '
    // },
    {
        id: 13,
        image: website11,
        name: "Film Director",
        category: "Website",
        description: 'Website '
    },
    {
        id: 14,
        image: website5,
        name: "Gyanav Solution Private Limited ",
        category: "Website",
        description: 'website '
    },
    // {
    //     id: 15,
    //     image: "website6.16a0d9ee.png",
    //     name: "Seemoss",
    //     category: "Website",
    //     description: 'website '
    // },
    {
        id: 16,
        image: website7,
        name: "Sanatan Express Logistics",
        category: "Website",
        description: 'website '
    },
    {
        id: 17,
        image: website8,
        name: "Legis facilitar Law Firm",
        category: "Website",
        description: 'website '
    },
    {
        id: 17,
        image: website9,
        name: "kccorganicminerals",
        category: "Website",
        description: 'website '
    },

    {
        id: 18,
        image: aap6,
        name: "FAMILY FM LTD.Antigua",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 19,
        image: aap1,
        name: "Bct Pay",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 20,
        image: aap11,
        name: "Soundchat Radio",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 21,
        image: aap3,
        name: "Reegur",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 21,
        image: aap2,
        name: "Payroos",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 22,
        image: aap5,
        name: "Time less ",
        category: "ios",
        description: 'Android App -> iOS App'
    },
    {
        id: 23,
        image: aap8,
        name: "Rock vibe gospal radio",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 24,
        image: aap4,
        name: "Catholic radio",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 25,
        image: website11,
        name: "Film Director",
        category: "website",
        description: 'website'
    },

];



export default MenuItems;